<template>
  <!-- 行驶数据 -->
  <div class="chart">
    <ol :class="$style.ageBar">
      <template v-for="(item, index) in vData">
        <li v-if="item.value > 0"
          :key="index"
          :style="`flex: ${item.value}; background-color: ${colors[index]}`"></li>
      </template>
    </ol>
    <ol :class="$style.ageLegend">
      <li v-for="(item, index) in vData" :key="index">
        <i :class="$style.dot" :style="`background-color: ${colors[index]}`"></i>
        <span>{{item.label}}</span>
        <!-- <strong>{{ageRatio[item.key]}}</strong> -->
        <strong>{{ageRatio[`${item.key}Ratio`]}}%</strong>
      </li>
    </ol>
  </div>
</template>

<style module lang="scss">
.ageBar {
  display: flex;
  padding: .4rem .2rem;

  li {
    height: .4rem;
    background-color: #9D54FF;
    transition: flex .3s;

    & + li {
      margin-left: .1rem;
    }

    &:first-child {
      border-radius: .4rem 0 0 .4rem;
    }

    &:last-child {
      border-radius: 0 .4rem .4rem 0;
    }
  }
}
.ageLegend {
  padding: .3rem .2rem;

  li {
    display: flex;
    margin-top: .2rem;
    align-items: center;
    line-height: 1rem;
    color: #888FCD;
    white-space: nowrap;

    .dot {
      margin-right: .3rem;
      width: .4rem;
      height: .4rem;
      overflow: hidden;
      border-radius: 50%;
      background: #9D54FF;
    }

    span {
      flex: 1.8;
    }

    strong {
      flex: 1;
      text-align: right;
      color: #fff;
      font-weight: normal;
    }
  }
}
</style>

<script>
import { TweenLite } from 'gsap';

export default {
  props: {
    title: {
      type: String,
    },
    large: {
      type: Boolean,
      default: false
    },
    vData: {
      type: Array,
      default: () => ([]),
    }
  },
  data() {
    const ageRatio = {};
    const ageRatioTemp = {};

    this.vData.forEach((item) => {
      ageRatio[item.key] = parseInt(item.value);
      ageRatio[`${item.key}Ratio`] = parseInt(item.ratio);
      ageRatioTemp[item.key] = item.value;
      ageRatioTemp[`${item.key}Ratio`] = item.ratio;
    })

    return {
      colors: ['#00F6E5', '#5CBC54', '#EE6F49', '#9D54FF'],
      ageRatio,
      ageRatioTemp
    };
  },
  watch: {
    vData(newVal) {
      const that = this;
      const { ageRatioTemp } = that;
      const _ageRatio = {};

      newVal.forEach((item) => {
        const { key, value, ratio } = item;

        _ageRatio[key] = value - 0;
        _ageRatio[`${key}Ratio`] = ratio;
      })

      TweenLite.to(ageRatioTemp, 0.3, {
        ..._ageRatio,
        onUpdate() {
          const ageRatio = {};

          Object.entries(ageRatioTemp).forEach(([key, value]) => {
            ageRatio[key] = parseInt(value);
          });

          that.ageRatio = ageRatio;
        }
      });
    },
  },
};
</script>